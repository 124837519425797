import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Clock from "./Clock/Clock";

let clocks = window.ENV?.REACT_APP_CLOCKS
  ? JSON.parse(window.ENV?.REACT_APP_CLOCKS)
  : JSON.parse(process.env.REACT_APP_CLOCKS);

for (let i = 0; i < clocks.length; i++) {
  let url = clocks[i];
  new Clock({ url, index: i });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
