export default class Clock {
  constructor({ url, index }) {
    this.url = url;
    this.index = index;
    this.connect();
  }

  connect() {
    this.ws = new WebSocket(this.url);
    this.ws.onopen = () => {
      console.log("Clock on open");
      clearInterval(this.clock_keep_alive);
      this.clock_keep_alive = setInterval(() => {
        this.ws.send(
          JSON.stringify({
            keep_alive: Date.now(),
            group: window.id,
          })
        );
      }, 20000);
    };
    this.ws.onmessage = (data) => {
      //console.log("Clock on message");
      try {
        let obj = JSON.parse(data.data);
        if (obj.hyperTime?.game) {
          document.getElementById("clock-" + this.index).innerText =
            obj.hyperTime?.game?.time;
        }
        if (obj.hyperTime?.shot) {
          document.getElementById("shot-clock-" + this.index).innerText =
            obj.hyperTime?.shot?.time;
        }
        //console.log(obj);
      } catch (err) {
        console.error(err);
      }
    };

    this.ws.onerror = (err) => {
      console.log("Clock on message", err);
      this.ws.close();
    };

    this.ws.onclose = (data) => {
      console.log("Clock on close");
      clearTimeout(this.timeout);
      clearInterval(this.clock_keep_alive);
      this.timeout = setTimeout(() => {
        this.connect();
      }, 1000);
    };
  }
}
