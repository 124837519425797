import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Hyper from "./Theme/Hyper";
import Main from "./components/Views/Main";
import Header from "./components/Header";

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ThemeProvider theme={Hyper}>
        <Switch>
          <Route path="/">
            <Header />
            <Main />
          </Route>
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

export default App;
