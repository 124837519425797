import React from "react";
import styled from "styled-components";

const Clock = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  background-color: rgb(24, 29, 37);
  padding: 0.5em;
  color: #babfcb;
  align-items: center;
  min-height: 80px;
  margin: 1em;
  justify-content: center;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
`;

const ClockText = styled.div`
  font-size: 50px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
function Main() {
  return (
    window.ENV?.REACT_APP_CLOCKS
      ? JSON.parse(window.ENV?.REACT_APP_CLOCKS)
      : JSON.parse(process.env.REACT_APP_CLOCKS)
  )?.map((clock, index) => {
    return (
      <Clock>
        <Row>{clock}</Row>
        <Row>
          <Column style={{ width: "200px" }}>
            <div>Game Clock</div>
            <ClockText id={"clock-" + index}></ClockText>
          </Column>{" "}
          <Column style={{ width: "200px" }}>
            <div>Shot Clock</div>
            <ClockText id={"shot-clock-" + index}></ClockText>
          </Column>
        </Row>
      </Clock>
    );
  });
}

export default Main;
