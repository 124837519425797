// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0e1219;
  color: #dfe9e8;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none; /* Likely future */
  height: 1080px;
}
/* ::selection {
  color: none;
  background: none;
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;gCAE8B;EAC9B,mCAAmC;EACnC,kCAAkC;EAClC,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,yBAAiB;UAAjB,iBAAiB,EAAE,kBAAkB;EACrC,cAAc;AAChB;AACA;;;GAGG","sourcesContent":["body {\n  margin: 0;\n  font-family: system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\",\n    \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\",\n    \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #0e1219;\n  color: #dfe9e8;\n  overflow: hidden;\n  user-select: none; /* Likely future */\n  height: 1080px;\n}\n/* ::selection {\n  color: none;\n  background: none;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
